import { bindCallback, of, concat, from, Subject, merge as mergeN } from 'rxjs'
import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ReactSVG } from 'react-svg'
import Trash from '../../assets/basketball/Trash.svg'
import Play from '../../assets/basketball/toolmenu/play_inactive.png'
import Rewind from '../../assets/basketball/toolmenu/rewind_inactive.png'
import Forward from '../../assets/basketball/toolmenu/playregion_inactive.png'
import PlayheadTop from '../../assets/basketball/timeline/playhead_marker.png'
import PlayheadMiddle from '../../assets/basketball/timeline/playhead_middle.png'
import PlayheadBottom from '../../assets/basketball/timeline/playhead_bottom.png'
import computeScrollIntoView from 'compute-scroll-into-view'
import './index.css'

let PLAYBACK_RATE = 0.75

const delay = async secs => new Promise(resolve => setTimeout(resolve, secs * 1000))

const isInRange = (x, start, end) => {
  x = timeToFrame(x)
  start = timeToFrame(start)
  end = timeToFrame(end)
  const result = x >= start && x < end
  //console.log(x, start, end, 'isInRange', result)
  return result
}

const clamp = (x, start, end) => {
  return Math.min(Math.max(x, start), end)
}

const scrollIntoView = node => {
  try {
    // same behavior as Element.scrollIntoViewIfNeeded(true)
    // see: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
    const actions = computeScrollIntoView(node, {
      scrollMode: 'if-needed',
      block: 'center',
      inline: 'center',
    })
    
    // Then perform the scrolling, use scroll-into-view-if-needed if you don't want to implement this part
    actions.forEach(({ el, top, left }) => {
      //  el.scrollTop = top
      el.scrollLeft = left
    })
  } catch (ignored) {
  }
}

const BBPauseButton = (props) => {
  const { onClick } = props
  return <div className='bbPauseButton' onClick={onClick}>
    <div className='bbPauseButtonGraphic'>
    <div className='bbPauseButtonBar'/>
    <div className='bbPauseButtonBar'/>
    </div>
    </div>
}


export const DragMove = (props) => {
  const {
    onPointerDown,
    onPointerUp,
    onPointerMove,
    onDragMove,
    children,
    style,
    className,
  } = props;

  const [isDragging, setIsDragging] = useState(false);

  const handlePointerDown = (e) => {
    setIsDragging(true);

    onPointerDown(e);
  };
  
  const handlePointerUp = (e) => {
    setIsDragging(false);
    
    onPointerUp(e);
  };
  
  const handlePointerMove = (e) => {
    if (isDragging) onDragMove(e);
    
    onPointerMove(e);
  };

  useEffect(() => {
    window.addEventListener('pointerup', handlePointerUp);

    return () => {
      window.removeEventListener('pointerup', handlePointerUp);
    }
  }, []);

  return (
   <div
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      style={style}
      className={className}
    >
      {children}
    </div>
  );
}

const { func, element, shape, bool, string } = PropTypes;

DragMove.propTypes = {
  onDragMove: func.isRequired,
  onPointerDown: func,
  onPointerUp: func,
  onPointerMove: func,
  children: element,
  style: shape({}),
  className: string,
}

DragMove.defaultProps = {
  onPointerDown: () => {},
  onPointerUp: () => {},
  onPointerMove: () => {},
};

const FADE_FRAMES = 15 / PLAYBACK_RATE
const FPS = 60
const pixelsPerFrame = 8

const frameToTime = frame => frame / FPS
const timeToFrame = time => Math.round(time * FPS)


class Playhead extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  onDragMove = e => {
    console.log(e.movementX, e.screenX, e.pageX)
    this.props.scrub(e.movementX / pixelsPerFrame + this.props.frame)
  }
  clampToFrame = () => {
    this.setState({
      dragging: false
    })
    this.props.scrub(Math.round(this.props.frame))
  }
  onPointerDown = e => {
    this.setState({
      dragging: true
    })
  }
  render() {
    const style = {
      left: this.props.frame * pixelsPerFrame,
    }
    let className = 'bbTimelinePlayheadTrack'
    if (this.state.dragging){
      className += ' bbTimelinePlayheadTrackActive' 
    }
    return <DragMove key='playhead' onDragMove={this.onDragMove} onPointerUp={this.clampToFrame} onPointerDown={this.onPointerDown}>
      <div className={className}>
      <div className='bbTimelinePlayhead' onDrag={e=> e.preventDefault()} style={style}>
      <div className='bbTimelinePlayheadTop'><img src={PlayheadTop}/></div>
      <div className='bbTimelinePlayheadMiddle' style={{backgroundImage: `url(${PlayheadMiddle})`}}/>
      {false && <div className='bbTimelinePlayheadBottom'><img src={PlayheadBottom}/></div>}
      </div>
      </div>
      </DragMove>
  }
}

let trackId = 0

class BBTrack {
  constructor(trax, move) {
    this.trax = trax
    this.move = move
    trackId++
    this.id = trackId
    if (false) this.sub = move.observeTimeUpdate().subscribe(currentTime => {
      //console.log('currentTime', currentTime, 'end', move.endTime)
      //console.log('currentFrame', timeToFrame(currentTime), 'end', timeToFrame(move.endTime))
      if (this.playing && timeToFrame(currentTime) >= timeToFrame(move.endTime)) {
        //currentTime >= move.endTime) {
        //this.onEnd()
      }
    })
  }

  release = () => {
    if (this.sub) this.sub.unsubscribe()
    this.stopTimeUpdate()
  }

  startTimeUpdate() {
    this.timeUpdate = setTimeout(() => {
      const move = this.move
      const currentTime = move.video.currentTime
      if (this.playing && !isInRange(currentTime, move.startTime, move.endTime)) {
        //currentTime >= move.endTime) {
        this.onEnd()
      } else {
        if (this.playing) {
          if (this.next) {
            this.next.move.prepare()
          }
          this.startTimeUpdate()
        }
      }
    }, 1/120 * 1000)
  }

  onEnd = async () => {
    const move = this.move
    console.log(move.move.name, "loop at ", move.endFrame, move.video.currentTime)
    if (this.next) {
      this.move.stop()
      this.playing = false
      this.stopTimeUpdate()
      console.log("playing next clip", this.next.move.move.name)
      this.next.playFromStart()
    } else {
      console.log("looping video")
      move.video.currentTime = move.startTime
      await move.video.pause()
      await delay(0.5)
      move.video.play()
      this.startTimeUpdate()
    }
  }

  stopTimeUpdate = () => {
    clearTimeout(this.timeUpdate)
  }

  stop = () => {
    this.stopTimeUpdate()
    this.playing = false
    this.move.stop()
  }

  playFromStart = async () => {
    this.playing = true
    this.move.playFromStart()
    this.startTimeUpdate()
    this.trax.onPlayTrack(this)
    if (this === this.trax.state.moves[0]) {
      this.move.video.pause()
      await delay(0.5)
      if (this.playing) {
        this.move.video.play()
      }
    }
  }

  play = () => {
    this.playing = true
    this.move.play()
    this.startTimeUpdate()
    this.trax.onPlayTrack(this)
  }


  pause = () => {
    if (!this.playing) return
    this.playing = false
    this.stopTimeUpdate()
    this.move.pause()
  }

  scrub = to => {
    this.stopTimeUpdate()
    this.move.scrub(to)
  }
    
}

class BBMove {

  constructor (move) {
    this.move = move
    this.setupClip()
  }

  dup = () => new BBMove(this.move)

  getTrackStyle = () => {
    const frames = this.move.endFrame - this.move.startFrame - 1
    const width = frames * pixelsPerFrame
    return { width }
  }

  setupClip = () => {
    this.startTime = frameToTime(this.move.startFrame-1)
    this.endTime = frameToTime(this.move.endFrame)
    if (this.video) {
      this.video.currentTime = this.startTime
    }
  }

  timeUpdateSubject = new Subject()

  observeTimeUpdate = () => {
    return this.timeUpdateSubject
  }

  setRef = video => {
    if (!video) {
      return
    }
    console.log("SET REF", this.move, video)
    this.video = video
    this.video.playbackRate = PLAYBACK_RATE
    this.video.onloadeddata = e => {
      this.video.currentTime = this.startTime
      this.video.ontimeupdate = e => {
        this.timeUpdateSubject.next(this.video.currentTime)
      }
      if (!this.playing) {
        this.video.pause()
      }     
    }
    this.video.onpause = e => {
      console.log(this.move.name, this.video.paused ? "paused" : "playing")
    }
  }

  visible: false
  opacity = 0

  scrub = to => {
    to = clamp(to, 0, this.endTime - this.startTime)
    this.visible = true
    if (this.video) {
      this.video.pause()
      this.crossFade()
      console.log(this.move.name, 'scrub', to)
      this.video.currentTime = to + this.startTime
    }
  }

  crossFade = () => {
    clearInterval(this.pauseFade)
    clearInterval(this.playFade)
    if (this.visible) {
      this.fadeIn()
    } else {
      this.fadeOut()
    }
  }

  fadeIn = () => {
    this.playFade = setInterval(() => {
      this.opacity += 1/FADE_FRAMES
      if (this.opacity >= 1) {
        this.opacity = 1
        clearInterval(this.playFade)
      }
      this.video.style.opacity = this.opacity
      //console.log("play opacity: ", this.opacity, this.video.style.opacity)
    }, 16)
  }

  fadeOut = () => {
    this.pauseFade = setInterval(() => {
      this.opacity -= 1/FADE_FRAMES
      if (this.opacity <= 0) {
        this.opacity = 0
        clearInterval(this.pauseFade)
      }
      this.video.style.opacity = this.opacity
      //console.log("pause opacity: ", this.opacity, this.video.style.opacity)
    }, 16)
  }

  playFromStart = () => {
    if (this.video) {
      //this.video.currentTime = this.startTime
    }
    this.play()
  }

  preparing = false

  prepare = () => {
    if (this.video && this.opacity === 0) {
      if (this.video.currentTime != this.startTime) {
        if (!this.preparing) {
          console.log("preparing", this.move.name)
          this.preparing = true
          this.video.currentTime = this.startTime
        }
      } else {
        if (this.preparing) {
          this.preparing = false
          console.log("prepared", this.move.name)
        }
      }
    } else {
      console.log("can't prepare", this.move.name, 'opacity', this.opacity)
    }
  }

  play = () => {
    this.preparing = false
    console.log("Playing", this.move.name)
    this.visible = true
    this.playing = true
    this.crossFade()
    this.video.play().catch(ignore => {
    })
  }

  stop = () => {
    this.visible = false
    this.pause()
  }
  
  pause = async () => {
    this.playing = false
    console.log("Pausing", this.move.name)
    if (this.video) {
      this.crossFade()
      this.video.pause()
    }
  }
}

export class Trax extends Component {

  constructor (props) {
    super(props)
    this.state = {
      currentFrame: 0,
      clips: [],
      moves: []
    }
  }

  allMoves = {}

  updateDur = () => {
    let dur = 0
    for (const track of this.state.moves) {
      const { move } = track
      move.setupClip()
      dur += move.endTime - move.startTime
    }
    this.dur = dur
  }

  play = () => {
    this.startTime = Date.now()
    //this.frameUpdate = setInterval(this.updateFrame, 32)
    const currentTime = (this.state.currentFrame / FPS) % this.dur
    let offset = 0
    for (const track of this.state.moves) {
      const dur = track.move.endTime - track.move.startTime
      if (offset + dur > currentTime) {
        console.log("Playing track", track.move.move.name)
        track.play()
        break
      } 
      offset += dur + frameToTime(1)
    }
    this.state.playing = true
    this.forceUpdate()
  }

  pause = () => {
    this.state.playing = false
    this.forceUpdate()
    this.scrub(this.state.currentFrame)
  }

  updateFrame = () => {
    let elapsed = ((Date.now() - this.startTime) / 1000) % this.dur
    const currentFrame = Math.round(elapsed * FPS)
    //console.log("elapsed", elapsed, currentFrame)
    this.setState({
      currentFrame
    })
  }

  fwd = () => {

  }

  rewind = () => {
    if (!this.playing) {
      this.scrub(0)
    }
  }

  scrub = frame => {
    if (this.state.playing) return
    this.state.currentFrame = frame
    //console.log("currentFrame", frame)
    const currentTime = frameToTime(frame)
    let offset = 0
    for (const track of this.state.moves) {
      const dur = track.move.endTime - track.move.startTime
      const move = track.move
      if (isInRange(currentTime, offset, offset + dur)) {
        console.log("isInRange", move.move.name, currentTime, offset, offset + dur)
        track.pause()
        track.scrub(currentTime - offset)
        if (this.state.currentTrack !== track) {
          this.onPlayTrack(track)
        }
      } else {
        console.log("not isInRange", move.move.name, currentTime, offset, offset + dur)
        track.stop()
      }
      offset += dur
    }
    this.forceUpdate(() => {
      const track = this.state.currentTrack
      if (track) {
        scrollIntoView(this.tracks[track.id])
      }
    })
  }

  componentDidMount() {
    this.props.me.observeMoves().subscribe(move => {
      this.allMoves[move.name] = new BBMove(move)
      this.updateMovesLater()
    })
    window.addEventListener('keydown', e => {
      switch (e.key) {
        case 'ArrowLeft':
          this.state.currentFrame--
          this.state.currentFrame = Math.max(this.state.currentFrame, 0)
          break
        case 'ArrowRight':
          this.state.currentFrame++
          break
        default:
          return
      }
      e.preventDefault()
      this.scrub(this.state.currentFrame)
    })
    
  }

  updateMovesLater = () => {
    clearTimeout(this.movesTimer)
    this.moveTimer = setTimeout(this.updateMoves, 200)
  }

  updateMoves = () => {
    this.forceUpdate()
  }

  removeTrack = i => {
    const track = this.state.moves[i]
    track.stop()
    track.release()
    const prev = this.state.moves[i-1]
    const next = this.state.moves[i+1]
    if (prev) {
      prev.next = next || this.state.moves.length == 2 ? null : this.state.moves[0]
    }
    if (next) {
      this.onPlayTrack(next)
    } else {
      this.onPlayTrack(prev)
    }
    this.state.moves.splice(i, 1)
    this.forceUpdate(() => {
      this.scrub(this.state.currentFrame)
    })
  }

  addTrack = move => {
    const prev = this.state.moves[this.state.moves.length-1]
    this.state.moves.push(move)
    if (prev) {
      prev.next = move
      move.next = this.state.moves[0]
    } else {
      move.next = null
    }
    this.updateDur()
    let f 
    if (this.state.playing) {
      this.play()
    } else {
      f = () => this.scrub(this.state.currentFrame)
    }
    this.forceUpdate(f)
  }

  onPlayTrack = track => {
    let dur = 0
    for (const t of this.state.moves) {
      if (t === track) {
        break
      }
      dur += t.move.endTime - t.move.startTime
    }
    if (this.state.currentTrack != track) {
      if (track) {
        console.log("on play track", track.move)
        clearInterval(this.timeUpdate)
        this.timeUpdate = setInterval(() => {
          if (this.state.playing) {
            const when = dur + Math.min(track.move.video.currentTime, track.move.endTime) - track.move.startTime
            this.state.currentFrame = timeToFrame(when)
            //console.log("currentFrame", this.state.currentFrame, "currentPixel", this.state.currentFrame * pixelsPerFrame)
            this.forceUpdate(() => {
              scrollIntoView(this.tracks[track.id])
            })
          }
        }, 16)
      }
      this.state.currentTrack = track
      this.forceUpdate()
    }
  }

  tracks = {}

  setTrackRef = (track, i, ref) => {
    this.tracks[track.id] = ref
  }

  setTrackMarksRef = ref => {
    this.trackMarksRef = ref
  }

  render() {
    const onClick = action => e => {
      e.preventDefault()
      action()
    }
    let currentTime = frameToTime(this.state.currentFrame).toFixed(2).split('.').join(':')
    let availableMoves = []
    const moves = this.state.moves
    let src = moves.length > 0 ? moves[moves.length-1] : null
    if (src) {
      src = src.move.move
    } else {
      src = { }
    }
    for (const name in this.allMoves) {
      const bbmove = this.allMoves[name]
      const { move } = bbmove
      if ((!src.endStance || (src.endStance !== 'n/a' && move.startStance === src.endStance)) && (!src.ballEnd || move.ballStart === src.ballEnd)) {
        availableMoves.push(bbmove)
      }
    }
    availableMoves.sort((x, y) => {
      return x.move.name.localeCompare(y.move.name)
    })
    return <div className='bbTrax'>
      <div className='bbTraxStage'>
      <div className='bbTraxCenterStage'>
      {this.state.moves.map(track => {
        const bbmove = track.move
        const { move } = bbmove
        return <div className='bbMoveVideo'>
          <video src={move.clip} autoPlay key={move.name} ref={bbmove.setRef} src={move.clip}/>
          </div>
      })
      }
    </div>
    </div>
      <div className='bbTrackTitle'>
      {this.state.currentTrack ? this.state.currentTrack.move.move.name : undefined}
      </div>
      <div className='bbTraxMenu'>
      {availableMoves.map(bbmove => {
        const onClick = () => this.addTrack(new BBTrack(this, bbmove.dup()))
        const { move } = bbmove
        return <div className='bbMoveMenuItem' onClick={onClick}>
          {false && <div className='bbMoveMenuItemClip'>
          <video src={`${move.clip}#t=${bbmove.startTime},${bbmove.endTime}`} autoPlay loop/>
           </div>}
          <div className='bbMoveMenuItemLabel'>{move.name}</div>
          </div>
      })
      }
      </div>
      <div className='bbTimelineControls'>
      <div className='bbTimelineButtons'>
      <div className='bbTimelineRewind' onClick={onClick(this.rewind)}><img src={Rewind}/></div>
      {this.state.playing ? <BBPauseButton onClick={onClick(this.pause)}/> :
       <div className='bbTimelinePlay' onClick={onClick(this.play)}><img src={Play}/></div>}
      <div className='bbTimelineForward' onClick={onClick(this.fwd)}><img src={Forward}/></div>
      </div>
      <div className='bbTimelineTime'>{currentTime}</div>
      <div className='bbTimelineTime'>{Math.round(this.state.currentFrame+1)}</div>
      </div>
      <div className='bbTimeline'>
      <div className='bbTimelineTop'>
      </div>
      <div className='bbTimelineMiddle'>
      </div>
      <div className='bbTimelineBottom'>
      <div className='bbTimelineTracks'>
      <div className='bbTimelineTrackList'>
      {
        this.state.moves.map((track, i) => {
        const style = this.state.playing ? { visibility: 'hidden' } : undefined          
        const bbmove = track.move
        const removeTrack = () => {
          this.removeTrack(i)
        }
        return <div ref={x => this.setTrackRef(track, i, x)} className='bbTimelineTrack' style={bbmove.getTrackStyle()}>
          <div className='bbTimelineTrackName'>{bbmove.move.name}</div>
          <div className='bbTimelineTrackDelete' style={style} onClick={removeTrack} ><ReactSVG src={Trash}/></div>
        </div>
      })
      }
    </div>
      <div ref={this.setTrackMarksRef} className='bbTimelineTrackMarks' onClick={e => {
        console.log(e)
        const x = e.clientX - this.trackMarksRef.getBoundingClientRect().left
        const frame = Math.round(x / pixelsPerFrame)
        console.log("onClick", x, '=>', frame)
        this.scrub(frame)
      }}>
      <Playhead key='theplayhead' frame={this.state.currentFrame} scrub={this.scrub}/>
      </div>
      </div>
      </div>
      </div>
      </div>
  }
  
}
