import { docData, doc, collectionChanges } from "rxfire/firestore"
import { bindCallback, of, concat, from, Subject, merge as mergeN } from 'rxjs'
import { catchError, filter, map, flatMap, take, merge } from 'rxjs/operators'
import axios from 'axios'
import { capitalize, mergeFields, formatAddress } from './Util.js'
import { Moves } from './Moves.js'

const debugLog = (...args) => {
}

export class Me {
  isNative = () => {
    return typeof window !== 'undefined' && window.ReactNativeWebView
  }

  sendNativeMessage = msg => {
    if (this.isNative()) {
      window.ReactNativeWebView.postMessage(JSON.stringify(msg))
    }
  }

  nativeLog = msg => {
    if (this.isNative()) {
      this.sendNativeMessage({
        type: 'log',
        message: msg
      })
    } else {
      debugLog(msg)
    }
  }
  
  nativeInit () {
    if (!this.nativeInitDone) {
      this.nativeInitDone = true
      if (typeof window !== 'undefined' && window.ReactNativeWebView) {
        this.nativeLog("native init done: "+window.postMessage);
        this.sendNativeMessage({
          type: 'config',
          config: this.config
        })
      }
    }
  }
  
  constructor (firebase, config) {
    this.firebase = firebase
    this.config = config
    const auth = this.firebase.auth();
    auth.onAuthStateChanged(this.onAuthStateChanged);
    this.onAuthStateChanged(auth.currentUser);
    this.config = config
    window.postMessage = this.onNativeMessage
    this.nativeInit()
  }

  providerTypeFilter = providerType => specialty => filterSpecialty(specialty, providerType)

  observeCustomer = () => {
    return doc(this.firebase.firestore().collection('Customers').doc(this.self.uid)).pipe(map(snap => {
      return snap.exists ? snap.data() : null
    }))
  }

  observePaymentMethods = () => {
    return this.observeCustomer().pipe(map(customer => {
      if (customer) {
        let { paymentMethods, selectedPaymentMethod } = customer
        if (paymentMethods && selectedPaymentMethod) {
          selectedPaymentMethod = paymentMethods.find(x => x.id === selectedPaymentMethod)
        }
        return { paymentMethods, selectedPaymentMethod }
      }
      return {}
    }))
  }

  getCurrentLocation = () => {
    if (typeof window !== 'undefined' && window.ReactNativeWebView) {
      return this.nativeCall({
        type: 'location'
      }).then(response => {
        return response.coords
      })
    }
    return getCurrentPosition()
  }

  observeMoves = () => {
    return from(Moves)
  }

  getLocation = async opts => {
    if (!opts) return this.getCurrentLocation()
    const { latitude, longitude, address } = opts
    console.log(opts)
    debugger
    const func = this.firebase.functions().httpsCallable('getLocation')
    const result = await func({
      lat: latitude,
      lng: longitude,
      address
    })
    const { results } = result.data
    const { geometry, address_components } = results[0]
    const streetNumber = address_components.find(x => x.types.find(y => y == 'street_number')).short_name
    const street = address_components.find(x => x.types.find(y => y == 'route')).short_name
    let suite = ''
    const subPremise = address_components.find(x => x.types.find(y => y == 'subpremise'))
    if (subPremise) {
      suite = `, ${subPremise.short_name}`
    }
    let lat, lng
    if (geometry) {
      lat = geometry.location.lat
      lng = geometry.location.lng
    }
    return {
      latitude: lat,
      longitude: lng,
      address: `${streetNumber} ${street}${suite}`,
      address_components
    }
  }
  
  onNativeMessage = json => {
    //this.nativeLog('onNativeMessage: ' + json)
    //if (json.source) return
    let msg
    try {
      msg = JSON.parse(json)
    } catch (err) {
      this.nativeLog('JSON.parse failed: ' + err.message)
      return
    }
    if (msg.type === 'token') {
      this.saveToken(msg.token)
    } else if (msg.type === 'notification') {
      //this.nativeLog("received not: " + msg.notification.data.type)
      this.notificationSubject.next(msg.notification)
    } else if (msg.type === 'safeArea') {
      window.safeAreaInsets = msg.safeArea
      //this.nativeLog("window.safeAreaInsets=>"+window.safeAreaInsets);
    } else if (msg.type === 'url') {
      //alert("initial url: " + msg.url)
      this.url = msg.url
      this.urlSubject.next(this.url)
    } else if (msg.type === 'creds') {
      this.creds = msg
      this.credsSubject.next(this.creds)
    } else if (msg.type === 'qrCode') {
      if (this.qrCodeInputSubject) {
        this.qrCodeInputSubject.next({
          type: msg.op,
          code: msg.qrCode
        })
      }
      if (this.qrCodeResult) {
        this.qrCodeResult(msg.qrCode)
      }
    } else if (msg.reqId) {
      const resolve = this.req[msg.reqId]
      if (resolve) {
        delete this.req[msg.reqId]
        resolve(msg)
      }
    }
  }

  setStatusBarColor = color => {
    debugLog('set status bar color:', color)
    this.sendNativeMessage({
      type: 'statusBarColor',
      color: color 
    })
  }

  selfSubject = new Subject()

  observeSelf = () => {
    const existing = this.self ? [this.self] : []
    return concat(existing, this.selfSubject)
  }
  
  onAuthStateChanged = user => {
    if (user && this.user && user.uid == this.user.uid) {
      return
    }
    this.self = user
    this.selfSubject.next(user)
  }

  emailExists = async email => {
    email = email.trim().toLowerCase()
    const func = this.firebase.functions().httpsCallable('emailExists')
    const result = await func({email})
    return result.data
  }

  phoneNumberExists = async phoneNumber => {
    const func = this.firebase.functions().httpsCallable('phoneNumberExists')
    const result = await func({phoneNumber})
    return result.data
  }

  verifyInvite = async (email, verificationCode) => {
    const func = this.firebase.functions().httpsCallable('verifyInvite')
    const arg = { email, verificationCode: Number(verificationCode) }
    const result = await func(arg)
    console.log('verifyInvite', arg, result)
    return result.data
  }

  createAccount = async (email, password) => {
    const result = await this.firebase.auth().createUserWithEmailAndPassword(email, password)
    return result.user
  }

  createCustomerAccount = async (verification, form) => {
    console.log('createCustomerAccount', verification, form)
    const { name, email, phoneNumber, address, country, state, zip, password } = form
    let user
    if (!this.self) {
      user = await this.createAccount(email, password)
    } else {
      user = this.self
    }
    const func = this.firebase.functions().httpsCallable('setupCustomerAccount')
    const { verificationCode } = verification
    const arg = {
      invite: { verificationCode: parseInt(verificationCode), email },
      form: {
        name, email, phoneNumber, address, country, state, zip
      }
    }
    console.log(arg)
    debugger
    const result = await func(arg)
    console.log(result)
    debugger
    if (result.error) {
      throw new Error(result.error)
    }
  }    
  
  signIn = async (email, password) => {
    email = email.trim()
    password = password.trim()
    const result = await this.firebase.auth().signInWithEmailAndPassword(email, password)
    this.onAuthStateChanged(result.user)
    const creds = {
      type: 'login',
      email: email,
      password: password,
      phoneNumber: result.user.phoneNumber
    }
    //alert('login ' + JSON.stringify(creds))
    this.sendNativeMessage(creds)
  }

  signInWithPhoneNumber = async (phoneNumber, recaptcha) => {
    return await this.firebase.auth().signInWithPhoneNumber(phoneNumber, recaptcha)
  }

  updatePassword = async newPassword => {
    await this.firebase.auth().currentUser.updatePassword(newPassword)
  }

  resetPassword = async email => {
    await this.firebase.auth().sendPasswordResetEmail(email);
  }

  signOut = async () => {
    this.signUpDisplayName = null;
    this.sendNativeMessage({
      type: 'signOut'
    })
    await this.firebase.auth().signOut()
  }
}


