import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'

import BxSignIn from '../../assets/Icons/bxSignin.svg'

import { isMobile, isDesktop } from '../../Platform.js'

import './index.css'

const enableDesktop = ()=> {
  const searchParams =  new URLSearchParams(window.location.search)
  return searchParams.has('enableDesktop')
}

const noSignup = () => isDesktop() && !enableDesktop()

export class BnStart extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }
 
  openEmail = () => window.open("mailto:employers@benxt.co?subject=More Infomation", "_blank")

  render() {
    return <div className='bnStart'>
      <div className='bnStartHeader'>
        <div className='bnStartLogo'></div>
        <div className='bnStartSignInButton' onClick={this.props.signIn}>
      {<div className='bnStartSignInButtonIcon'><ReactSVG src={BxSignIn}/></div>}
      {<div className='bnStartSignInButtonLabel'>SIGN IN</div>}
        </div>
      </div>

      <div className='bnStartBody'>
        <div className='bnStartJoinButton' onClick={this.props.signUp}>
          {"Join"}
        </div>
        </div>
    </div>
  }
}
