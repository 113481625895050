import { Subject, merge } from 'rxjs'
import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import {loadStripe} from '@stripe/stripe-js';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import { BnSplash } from '../Splash'
import { BnApp } from '../App'
import { Me } from '../../classes/Me'
import { isSafari, isDesktop, isMobile, isIOS } from '../../Platform.js'
import './index.css'


const firebaseConfig = {
  apiKey: "AIzaSyAt4Jvp2vg9A-4bF0EdbsfwJWAp5T0IUls",
  authDomain: "basketball-780ce.firebaseapp.com",
  projectId: "basketball-780ce",
  storageBucket: "basketball-780ce.appspot.com",
  messagingSenderId: "160225446625",
  appId: "1:160225446625:web:ce52274c332aebcd3a05a0"
};

export const stripePromise = () => loadStripe(stripeKeys[getEnv()])

const getFirebaseConfig = () => {
  return firebaseConfig
}

const config = getFirebaseConfig()

console.log('firebaseConfig', config)
firebase.initializeApp(config)

const me = new Me(firebase, config)

export class BnClient extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    me.observeSelf().subscribe(self => {
      this.setState({self})
    })
  }

  getPlatform = () => {
    let bxPlatName = 'bnClient'

    if (isMobile()) {
      bxPlatName += ' benxtAppMobile'
    }

    if (isIOS) {
      bxPlatName += ' benxtAppIOS'
    }

    if(isDesktop()) {
      bxPlatName += ' benxtAppDesktop'
    }

    if (isSafari) {
      bxPlatName += ' benxtAppSafari'
    }
    return(bxPlatName);
  }

  render () {
    const self = this.state.self
    return <div className={this.getPlatform()}>
        <BnApp self={self} me={me}/>
    </div>
  }
}
