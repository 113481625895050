import Vid3 from './clips/MoveComposerProject2.mov'

export const Moves = [
  {
    clip: Vid3,
    name: 'Crossover',
    startStance: 'n/a',
    endStance: 'inverted-drop',
    dir: 'right-to-left',
    startFrame: 25,
    endFrame: 91,
    ballStart: 'right',
    ballEnd: 'left',
  },
  {
    clip: Vid3,
    name: 'Through Exchange',
    startStance: 'neutral',
    endStance: 'neutral',
    dir: 'right-to-left',
    ballStart: 'right',
    ballEnd: 'left',
    startFrame: 93,
    endFrame: 114,
  },
  {
    clip: Vid3,
    name: 'Inverted Drop',
    startStance: 'neutral',
    endStance: 'inverted-drop',
    dir: 'right-to-left',
    ballStart: 'left',
    ballEnd: 'left',
    startFrame: 958,
    endFrame: 992,
  },
  {
    clip: Vid3,
    name: 'Inverted Through Drag',
    startStance: 'inverted-drop',
    endStance: 'neutral',
    dir: 'left-to-right',
    ballEnd: 'right',
    ballStart: 'left',
    startFrame: 148,
    endFrame: 203,
  },
  {
    clip: Vid3,
    name: 'Inverted Cross Drag',
    startStance: 'inverted-drop',
    endStance: 'neutral',
    dir: 'left-to-right',
    ballEnd: 'right',
    ballStart: 'left',
    startFrame: 205,
    endFrame: 252,
  },
  {
    clip: Vid3,
    name: 'Inverted Behind Drag',
    startStance: 'inverted-drop',
    endStance: 'neutral',
    dir: 'left-to-right',
    ballEnd: 'right',
    ballStart: 'left',
    startFrame: 253,
    endFrame: 315,
  },
  {
    clip: Vid3,
    name: 'Drop',
    startStance: 'neutral',
    endStance: 'drop',
    dir: 'left-to-right',
    ballStart: 'left',
    ballEnd: 'left',
    startFrame: 994,
    endFrame:  1021,
  },
  {
    clip: Vid3,
    name: 'Cross Drag',
    startStance: 'drop',
    endStance: 'neutral',
    dir: 'left-to-right',
    ballStart: 'left',
    ballEnd: 'right',
    startFrame: 353,
    endFrame: 375,
  },
  {
    clip: Vid3,
    name: 'Through Drag',
    startStance: 'drop',
    endStance: 'neutral',
    dir: 'left-to-right',
    ballStart: 'left',
    ballEnd: 'right',
    startFrame: 417,
    endFrame: 460,
  },
  {
    clip: Vid3,
    name: 'Inverted Stepback',
    startStance: 'drop',
    endStance: 'neutral',
    dir: 'right-to-left',
    ballStart: 'left',
    ballEnd: 'both',
    startFrame: 462,
    endFrame: 522,
  },
  {
    clip: Vid3,
    name: 'Inverted Spin Move + Go',
    startStance: 'drop',
    endStance: 'n/a',
    dir: 'right-to-left',
    ballStart: 'left',
    ballEnd: 'right',
    startFrame: 526,
    endFrame: 580,
  },
  {
    clip: Vid3,
    name: 'Behind + Go',
    startStance: 'drop',
    endStance: 'n/a',
    dir: 'right-to-left',
    ballStart: 'left',
    ballEnd: 'right',
    startFrame: 600,
    endFrame: 683,
  },
  {
    clip: Vid3,
    name: 'Inverted Behind Speed Stop',
    startStance: 'drop',
    endStance: 'neutral',
    dir: 'left-to-right',
    ballStart: 'left',
    ballEnd: 'right',
    startFrame: 685,
    endFrame: 720,
  },
  {
    clip: Vid3,
    name: 'Through Speed Stop',
    startStance: 'inverted-drop',
    endStance: 'neutral',
    dir: 'left-to-right',
    ballStart: 'left',
    ballEnd: 'right',
    startFrame: 726,
    endFrame: 768,
  },
  {
    clip: Vid3,
    name: 'Stepback',
    startStance: 'inverted-drop',
    endStance: 'neutral',
    dir: 'left-to-right',
    ballStart: 'left',
    ballEnd: 'both',
    startFrame: 770,
    endFrame: 812,
  },
  {
    clip: Vid3,
    name: 'Through Cross',
    startStance: 'neutral',
    endStance: 'neutral',
    dir: 'left-to-right',
    startFrame: 818,
    endFrame: 875,
    ballStart: 'left',
    ballEnd: 'left'
  },
  {
    clip: Vid3,
    name: 'Cross Cross',
    startStance: 'n/a',
    endStance: 'neutral',
    dir: 'right-to-left',
    startFrame: 893,
    endFrame: 939,
    ballStart: 'right',
    ballEnd: 'left',
  },
]


