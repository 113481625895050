import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { isAndroid, isIPad, isDesktop } from '../../classes/Platform'
import { BnForm, BnFormFieldSeparator as Sep } from '../Form'
import { BnLabel1, BnLabel2 } from '../Label'
import { BnButton, BnBackButton, BnOKButton, BnLogoSmall, BnMenu, BnSearchResult } from '../Button'
import './index.css'

export class BnSubpage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      form: {}
    }
  }

  renderContent() {
    return <div className='bnContentPage'>
      </div>
  }

  back = () => {
    this.setState({
      subpage: null
    })
  }

  getForm = () => this.props.form || this.state.form
  getFormErr = () => this.props.reportFormErr ? this.props.formErr : this.state.formErr

  reportFormErr = formErr => {
    console.log(formErr)
    if (this.props.reportFormErr) {
      return this.props.reportFormErr(formErr)
    }
    this.setState({
      formErr
    })
  }

  set = (field, value) => {
    this.onChange(field, value)
  }

  get = field => {
    return this.getForm()[field]
  }

  onChange = (field, value) => {
    if (this.props.onChange) {
      this.reportFormErr(null)
      return this.props.onChange(field, value)
    }
    const form = this.state.form
    this.state.form[field] = value
    this.state.formErr = null
    this.forceUpdate()
  }

  renderTitle = () => {
    return [<BnLabel1 text={this.props.title}/>,
            this.props.subtitle && <BnLabel2 text={this.props.subtitle}/>]
  }

  renderPopupTitle() {
    return <div className='bnPagePopupTitle'>
      {this.renderTitle()}
      </div>
  }
 
  render () {
    let subpage
    if (this.state.subpage) {
      subpage = this.state.subpage()
    }
    const content = this.renderContent()
    //console.log('content', content)
    console.log('actionLabel: ', this.props.actionLabel)
    return <div className='bnContentPage'>
      <BnPage me={this.props.me} subpage={subpage}>
      <BnForm>
      <BnLogoSmall/>
      <Sep/>
      {(this.props.back || this.props.action) &&
       [<div className='bnPageButtons'>
        {this.props.back && <BnBackButton action={this.props.back}/>}
        {this.props.action && this.renderPopupTitle()}
        {this.props.action && <BnOKButton label={this.props.actionLabel || this.state.actionLabel} action={()=>this.props.action(this.getForm())}/>}
        </div>,
        <Sep/>] }
    {!this.props.action && this.renderTitle()}
      <Sep/>
      {content}
      </BnForm>
        </BnPage>
      </div>
  }
}


export class BnPage extends Component {

  constructor (props) {
    super(props)
    this.state = {
      alpha: 0
    }
    if (!props.me) {
      debugger
    }
  }

  componentDidMount () {
    if (this.props.onCreate) this.props.onCreate(this)
    const popup = this.props.popup
    if (popup) this.slide(1)
  }

  componentDidUpdate (prevProps) {
    const subpage = this.props.subpage
    if ((subpage && !prevProps.subpage) || (!subpage && prevProps.subpage)) {
      if (subpage) {
        this.slide(1)
      } else {
        this.slide(0)
      }
    }
    const popup = this.props.popup
    if ((popup && !prevProps.popup) || (!popup && prevProps.popup)) {
      if (popup) {
        this.slide(1)
      } else {
        this.slide(0)
      }
    }
    if (subpage) {
      this.state.subpage = subpage
      this.state.popup = null
    }
    if (popup) {
      this.state.popup = popup
      this.state.subpage = null
    }
  }

  slide = (target) => {
    const pageScroller = this.pageScroller
    if (target === 1) {
      this.scrollPos = pageScroller.scrollTop
      pageScroller.scrollTop = 0
    } else {
      pageScroller.scrollTop = this.scrollPos
    }
    if (this.target === target) return
    this.target = target
    this.start = Date.now()
    this.dur = 600;
    if (this.timer) return
    this.timer = setInterval(() => {
      window.requestAnimationFrame(() => {
        const start = this.start
        const dur = this.dur
        const elapsed = (Date.now() - start)
        let t = Math.min(elapsed / dur, 1.0)
        t = 1 - Math.pow(1 - t, 7)
        const target = this.target
        const updates = {
          alpha: target ? t : 1.0 - t
        }
        if (target) {
          if (this.state.alpha < 0.5 && t >= 0.5) {
            if (this.state.popup) {
              this.props.me.setStatusBarColor('light-content')
            }
          }
          if (this.props.subpage) {
            if (!this.state.subpage) {
              updates.subpage = this.props.subpage
            }
          }
          else if (this.props.popup) {
            if (!this.state.popup) {
              updates.popup = this.props.popup
            }
          }
        } else {
          if (this.state.alpha > 0.5 && t <= 0.5) {
            if (this.state.popup) {
              //this.props.me.setStatusBarColor('dark-content')
            }
          } else {
            if (this.props.me.self) {
              this.props.me.setStatusBarColor('light-content')
            }
          }
          if (updates.alpha === 0) {
            updates.subpage = null
            updates.popup = 0
          }
        }
        this.setState(updates, () => {
          if (this.props.onAlphaChange) this.props.onAlphaChange(this.state.alpha)
        })
        if (t === 1) {
          this.endSlide()
        }
      })
    }, 16)
  }

  endSlide = () => {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    if (this.state.alpha == 0) {
      if (!this.props.me.self) {
        //this.props.me.setStatusBarColor('dark-content')
      } else {
        this.props.me.setStatusBarColor('light-content')
      }
    }
  }

  renderPopup (popupBgStyle, popupStyle) {
    let className = 'bnPagePopup'
    if (!this.props.me.isNative() && !isIPad()) {
      className += " bnMobileWebPopup"
    }
    const popup = this.props.popup || this.state.popup;
    if (popup) {
      if (true ||  popupBgStyle) {
        const n = <div className='bnPagePopupBg' style={popupBgStyle}><div className={className} style={popupStyle}>{popup}</div></div>
          const portalRef = getPortal()
        return ReactDOM.createPortal(n, portalRef)
      }
      return (
          <div className={className} style={popupStyle}>
          {popup}
        </div>
      )
    }
    return null
  }

  scrollPos = 0;

  setScrollRef = n => {
    if (n) {
      this.pageScroller = n
      const self = this
      this.pageScroller.onscroll = (e) => {
        console.log('scroll: ', this.props.title, n.scrollTop)
        if (this.state.popup || this.state.subpage) {
          e.preventDefault()
          n.scrollTo(0, this.scrollPos)
          return
        }
        const pos = n.scrollTop
        //console.log('pos=', pos)
        if ((this.scrollPos <= 40 && pos > 40) || (this.scrollPos > 40 && pos <= 40)) {
          this.scrollPos = pos
          this.setState({
            scrolled: pos > 40
          })
        } else {
          this.scrollPos = pos
        }
      }
      if (this.scrollPos !== n.scrollTop) {
        this.scrollPos = n.scrollTop
      }
    }
  }

  back = async () => {
    //console.log("alpha:", this.state.alpha, "leaving:", this.leaving)
    if (this.state.alpha !== 0) {
      return
    }
    return this.props.back()
  }

  cancel = async () => {
    if (this.state.alpha !== 0) {
      return
    }
    return this.props.cancel()
  }

  next = async (arg) => {
    if (this.state.alpha !== 0) {
      return
    }
    return this.props.done(arg)
  }
  
  renderSafeArea = () => {
    const self = this
    if (this.props.safeArea) {
      let style
      if (isAndroid() && window.safeAreaInsets) {
        const { top, bottom } = window.safeAreaInsets
        style = {
          height: (top + bottom)+'px'
        }
      }
      return <div className='bnPageHeaderSafeArea' style={style}/>
    }
    return null
  }

  render () {
    const ipad = isIPad() || (isDesktop() && window.innerWidth > 600)
    const popup = this.props.popup || this.state.popup || null
    const subpage = this.props.subpage || this.state.subpage || null
    let pageStyle
    let popupStyle
    let bodyStyle
    let cameraStyle
    let popupBgStyle
    let scrollerStyle
    let scrollTitle
    let isMobileWeb = false
    if (!this.props.me.isNative() && !isIPad()) {
      isMobileWeb = true
    }
    //console.log("render.scroll", this.props.title, this.scrollPos)
    if (this.state.scrolled || this.props.embed) {
      scrollTitle = this.props.title
    }
    if (this.props.noHeader) {
      scrollerStyle = { maxHeight: '100%' }
    }
    if (this.props.header) {
      scrollerStyle = { maxHeight: '100%' }
    }

    if (subpage) {
      const x = -(this.state.alpha * 100) + '%'
      pageStyle = { transform: 'translate(' + x + ', 0)' }
    } else if (popup) {
      const y = (1.0 - this.state.alpha) * 100 + '%'
      let r = this.state.alpha * 10
      let z = -2.5
      const rot = this.state.alpha * 1
      let t1 = 35
      let t2 = 40
      if (isAndroid() || isMobileWeb) {
        //z = -1.5
        if (!window.ReactNativeWebView) {
          t1 = 0
        } else {
          t1 = 30
        }
        //r = 0
      }
      const t = this.state.alpha * z
      let dist = Math.max(t2 * window.innerHeight / 800, t2)
      if (window.ReactNativeWebView && !isIPad()) {
        t1 += 20
        dist = 22
      }
      if (ipad) {
        t1 = 20
        let w = window.innerWidth - 40
        if (!isIPad()) {
          w = Math.min(w, 600)
        }
        popupStyle = {
          position: 'fixed',
          width: w,
          maxHeight: 'calc(100% - 60px)',
          left: (window.innerWidth - w) / 2,
          top: 20,
          transform: `translate(0, calc(${y} + ${t1}px))`,
          //borderRadius: '10px 10px 10px 10px',
          zIndex: 150,
          overflow: 'auto'
        }
        popupBgStyle = {
          top: 0,
          left: 0,
          position: 'fixed',
          height: '100%',
          width: '100%',
          background: `rgb(0, 0, 0, ${this.state.alpha * 0.2})`,
          zIndex: 100
        }
      } else {
        popupStyle = {
          transform: `translate(0, calc(${y} + ${t1}px))`,
          //borderRadius: `${r}px ${r}px 0px 0px`,
        }
      }
      const start = 'rgb(239, 239, 244)'
      const end = '#bababa'
      if (!ipad) {
        pageStyle = {
          transform: `translateZ(${t}cm) rotateX(${rot}deg)`,
          //borderRadius: `${r}px ${r}px 0px 0px`,
          //background: lerpColor(start, end, this.state.alpha),
          overflow: 'hidden'
        }
        cameraStyle = {
          perspective: `${dist}cm`
        }
      }
    }
    if (this.props.customSearch) {
      bodyStyle = {
        height: 'calc(100% - 64px)'
      }
    }
    return (
      <div className='bnPageBackground'>
        <div className={'bnPage'  + (this.props.embed ? ' bnPageEmbed' : '')}>
          <div className='bnPageCamera' style={cameraStyle}>
        <div className='bnPageContent' style={pageStyle}>
        {this.renderSafeArea()}
              <div className='bnPageScroller' ref={this.setScrollRef} style={scrollerStyle}>
                <div className='bnPageBody' style={bodyStyle}>
                  {this.props.children}
                </div>
                {this.props.subpages ? <div className='bnPageSubpage'>
                 {
                   this.props.subpages.map(page => {
                       return <div className='bnSubpageContainer' style={subpage ? null : { display: 'none'}}>
                       {page}
                     </div>
                   })
                 }
                 </div> : subpage ? <div className='bnPageSubpage'>{subpage}</div> : null}

              <div className='bnPageFooter'>{this.props.footer}</div>
        
      </div>
        </div>
          </div>
          {this.renderPopup(popupBgStyle, popupStyle)}
        </div>
      </div>
    )
  }
}
