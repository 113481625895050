import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { BnStart } from '../Start'
import { BnSignIn } from '../SignIn'
import { BnLabel1, BnLabel2 } from '../Label'
import { BnPage, BnSubpage } from '../Page'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { BnButton, BnBackButton, BnSelectionList, BnSelectionListChooser } from '../Button'
import { BnInputField, BnInputFieldSeparator } from '../TextInput'
import { Trax } from '../Trax'
import BxLogo from '../../assets/Marks/bxLogo.svg'
import phone from 'phone';
import './index.css'


class BnPhoneSignIn extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }


  render() {
    const subpage = this.state.subpage ? this.state.subpage() : null
    const form = this.props.form
    console.log(form)
    let content
    let subtitle
    let title = this.props.title
    if (form.onVerificationCodeInput) {
      content = <div className='bnFormFieldsContainer'>
        <BnLabel2 text='Please enter the code sent to your mobile device'/>
        <Sep/>
        <BnFormFields><BnInputField key='code' name='verificationCode' label={'Verification Code'} formErr={this.props.formErr} form={form} type='one-time-code' onChange={this.props.onChange}/></BnFormFields>
        </div>
    } else if (form.user) {
      title = 'Account'
      content =
      <BnPage subpage={subpage} me={this.props.me}>
        <div className='bnFormFieldsContainer'>
        <BnLabel2 text='Please complete your account information'/>
        <Sep/>
        <BnInputField key='name' autoFocus={true} autoComplete={'name'} name='displayName' label={'First and Last Name'} formErr={this.props.formErr} form={form} type='name' onChange={this.props.onChange}/>
        <BnInputFieldSeparator/>
        <BnInputField key='email' autoComplete={'email'} name='email' label={'Email Address'} formErr={this.props.formErr} form={form} type='email' onChange={this.props.onChange}/>
        <BnInputFieldSeparator/>
        <Sep/>
        <BnLabel1 text='Password'/>
        <Sep/>
        <BnLabel2 text='Your password must contain at least 8 characters, a mixture of upper and lower case letters with the inclusion of at least one special character'/>
        <Sep/>
        <BnInputField key='password' name='password' autoComplete={'new-password'} label={'Password'} formErr={this.props.formErr} form={form} type='password' onChange={this.props.onChange}/>
        </div>
        </BnPage>
    } else {
        content = <div className='bnFormFieldsContainer'>
        <BnLabel2 text='Please enter your mobile phone number'/>
        <Sep/>
        <BnFormFields><BnInputField key='phone' autoFocus={true} name='phoneNumber' label={'Phone Number'} formErr={this.props.formErr} form={form} type='tel' onChange={this.props.onChange} autoComplete={'tel'}/></BnFormFields>
        </div>
    }
    return <div className='bnSignIn'>
      <BnPage subpage={subpage} me={this.props.me}>
      <BnForm>
      <div className='bnSignInLogo'></div>
      <BnBackButton action={this.props.back}/>
      {this.props.selectEmailSignIn && <div className='bnSignInChoice' onClick={this.props.selectEmailSignIn}>Sign in with email address</div>}
      <Sep/>
      <BnLabel1 text={title}/>
      {content}
      <Sep/>
      <div className='bnSignInErr'>{this.props.formErr && this.props.formErr.message}</div>
      <BnButton label={'Continue'} action={this.props.action}/>
      </BnForm>
      </BnPage>
      </div>
  }
}

export class BnApp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      form: {
        email: '',
        password: '',
      },
      formErr: '',
      emailSignIn: false
    }
  }

  componentDidMount() {
    this.props.me.observeSelf().subscribe(self => {
      if (self && !(self.email && self.displayName)) {
        this.state.needsSignIn = true
        this.state.form.phoneNumber = self.phoneNumber
        this.state.form.email = self.email || ''
        this.state.form.displayName = self.displayName || ''
        this.state.form.verified = true
        this.state.form.dob = self.dob
        this.state.form.gender = self.gender
        this.state.form.user = self
        this.forceUpdate()
      }
    })
  }

  componentWillUnmount() {
  }

  doSignIn = () => {
    this.setState({
      needsSignIn: true,
      needsSignUp: false
    })
  }

  doSignUp = () => {
    this.setState({
      needsSignIn: false,
      needsSignUp: true
    })
  }

  signIn = async () => {
    const form = this.state.form
    let err
    if (!form.email) {
      err = {field: 'email', message: 'Email address is required'}
      this.setState({formErr: err})
      return
    }
    if (!form.password) {
      err = {field: 'password', message: 'Password is required'}
      this.setState({formErr: err})
      return
    }
    try {
      await this.props.me.signIn(form.email, form.password)
      this.setState({
        needsSignIn: false
      })
    } catch (err) {
      console.log(err)
      const message = err.message;
      let formErr
      if (err.code == "auth/user-not-found") {
        formErr = {field: 'email', message: "No user with that email."};
      } else if (err.code == "auth/too-many-requests") {
        formErr = {field: 'email', message: "Too many attempts. Try again later."};
      } else if (err.code == "auth/wrong-password") {
        formErr = {field: 'password', message: "Password incorrect."};
      } else if (err.code == "auth/network-request-failed") {
        formErr = {message: "You are not connected to the Internet."};
      } else if (err.code) {
        formErr = {field: '', message: err.message};
      } else {
        return Promise.reject(err);
      }
      this.setState({
        formErr
      })
    }
  }

  onChangeForm = (field, value) => {
    console.log("onChangeForm", field, value)
    this.state.form[field] = value
    this.state.formErr = null
    this.forceUpdate()
  }

  createCustomerAccount = async (verification, form) => {
    await this.props.me.createCustomerAccount({verification, form})
  }

  signUp = async () => {
    const form = this.state.form
    if (!form.phoneNumber) {
      this.setState({
        formErr: {
          field: 'phoneNumber',
          message: 'Phone number is required.'
        }
      })
      return
    }
    let phoneNumber = form.phoneNumber
    const converted = phone(phoneNumber);
    if (!converted.length) {
      this.state.formErr = {field: 'phoneNumber', message: 'Invalid phone number.'};
      this.forceUpdate()
      return
    } else {
      phoneNumber = converted[0];
    }
    debugger
    if (!this.props.me.self && this.state.needsSignUp) {
      const { exists } = await this.props.me.phoneNumberExists(phoneNumber)
      debugger
      if (exists) {
        this.state.formErr = {field: 'phoneNumber', message: 'Account exists with that phone number.'};
        this.forceUpdate()
        return;
      }
    }
    if (!form.verified) {
      if (!form.onVerificationCodeInput) {
        if (!this.verifier) {
          this.verifier = new this.props.me.firebase.auth.RecaptchaVerifier(this.recaptchaFun, {
            size: "invisible",
            callback: response => {
            }
          });
          this.recaptchaFun.style.display = "";
        }
        const recaptcha = this.verifier;
        console.log("sign in with phone number", phoneNumber)
        let result
        try {
          result = await this.props.me.signInWithPhoneNumber(phoneNumber, recaptcha)
        } catch (err) {
          let message = err.message
          debugger
          if (err.code === 'auth/quota-exceeded') {
            message = 'Too many sign-in attemps from this number. Try again a later.'
          } else if (err.code == "auth/too-many-requests") {
            message = 'Too many attempts. Try again later.'
          }
          this.setState({
            formErr: { field: 'phoneNumber', message: message }
          })
          return
        }
        console.log(result)
        form.onVerificationCodeInput = async code => {
          try {
            const result1 = await result.confirm(code)
            form.user = result1.user
            if (form.user.email && form.user.displayName) {
              return this.signInComplete()
            }
            form.onPasswordInput = null;
            form.onVerificationCodeInput = null;
            form.verified = true
            this.forceUpdate()
          } catch (err) {
            console.error(err)
            this.state.formErr = {field: 'verificationCode', message: "Invalid verification code."};
            this.forceUpdate()
          }
        }
        this.forceUpdate()
      } else {
        if (!form.verificationCode) {
          this.state.formErr = {field: 'verificationCode', message: "Verification code is required."};
          this.forceUpdate()
          return
        }
        await form.onVerificationCodeInput(form.verificationCode)
      }
    } else {
      if (!form.displayName) {
        const err = {field: 'displayName', message: 'First and Last Name is required.'}
        this.setState({formErr: err})
        return
      }
      if (!form.email) {
        const err = {field: 'email', message: 'Email Address is required.'}
        this.setState({formErr: err})
        return
      }
      if (!form.password) {
        const err = {field: 'password', message: 'Password is required.'}
        this.setState({formErr: err})
        return
      }
      const { email, password, displayName } = form
      const user = this.props.me.self
      await user.updateEmail(email)
      await user.updatePassword(password)
      await user.updateProfile({ displayName })
      this.signInComplete()
    }
  }

  signInComplete = () => {
    this.setState({
      needsSignUp: false,
      needsSignIn: false
    })
    this.clearForm()
  }

  clearForm = () => {
    this.state.form = {}
    this.state.formErr = {}
    this.forceUpdate()
  }

  back = async () => {
    if (this.props.me.self) {
      await this.props.me.signOut()
    }
    this.clearForm()
    this.setState({
      needsSignUp: false,
      needsSignIn: false
    })
      
  }

  renderEmailSignIn() {
    return <BnSignIn back={this.back} onChange={this.onChangeForm} action={this.signIn} recoverPassword={this.recoverPassword} formErr={this.state.formErr} form={this.state.form} me={this.props.me} selectPhoneSignIn={this.selectPhoneSignIn}/>
  }

  renderSignUp() {
    return <BnPhoneSignIn isSignUp={true} title={'Join'} back={this.back} form={this.state.form}  onChange={this.onChangeForm} action={this.signUp} formErr={this.state.formErr} me={this.props.me}/>
  }

  selectEmailSignIn = () => {
    this.setState({
      emailSignIn: true
    })
  }

  selectPhoneSignIn = () => {
    this.setState({
      emailSignIn: false
    })
  }

  renderSignIn() {
    console.log("renderSignIn", this.state.emailSignIn)
    if (this.state.emailSignIn) {
      return this.renderEmailSignIn()
    }
    return <BnPhoneSignIn title={'Sign In'} back={this.back} form={this.state.form}  onChange={this.onChangeForm} action={this.signUp} formErr={this.state.formErr} selectEmailSignIn={this.selectEmailSignIn} me={this.props.me}/>
  }

  renderContent() {
    if (this.props.me.self) {
      return <Trax me={this.props.me}/>
    }
  }

  recoverPassword = () => this.props.me.resetPassword(this.state.form.email)

  render() {
    let content
    let subpage
    if (this.state.needsSignUp) {
      subpage = this.renderSignUp()
    } else if (this.state.needsSignIn) {
      subpage = this.renderSignIn()
    } else {
      if (this.props.me.self) {
        subpage = this.renderContent()
      }
    }
    content = subpage ? null : <BnStart signIn={this.doSignIn} signUp={this.doSignUp}/>
      console.log("renderApp", content)
    return <div className='bnApp'>
    <div key='recaptcha-fun' id='recaptcha-fun' ref={ref=>{if (ref) this.recaptchaFun=ref}}/>
      <BnPage me={this.props.me} subpage={subpage}>
    {content}
    </BnPage>
    </div>
  }
}
